import type { AppProps } from "next/app";
import React, { useEffect } from "react";
import {
  apikeyGa,
  apikeyGtm,
  checkSupportWebp,
  cookieUtil,
  DAYJSEN,
  DAYJSZH,
  EN,
  FORMATWEBP,
  GA_EVENT_NAME,
  getSessionStorageTokenKey,
  getQueryValue,
  handleRouteChangeStart,
  i18nUtil,
  IS_AUTHENTICATED,
  isDev,
  LANGUAGE,
  reportEvent,
  USER,
  USER_AUTH,
  ZH_CN
} from "@aspen/libs/index";
import type { NextRouter } from "next/router";
import { useRouter } from "next/router";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { PersistGate } from "redux-persist/integration/react";
import { WithLogging } from "@aspen/ui";
import { store } from "@aspen/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import type { InspectParams } from "react-dev-inspector";
import { Inspector } from "react-dev-inspector";
import type { NextComponentType, NextPageContext } from "next/types";
import { ThemeProvider } from "next-themes";

require("@aspen/theme/app.less");

require("@aspen/theme/iconfont/iconfont.css");

const persistor = persistStore(store, {}, () => {
  persistor.persist();
});

type IProps = {
  Component: NextComponentType<NextPageContext, unknown>; // any 不修改
  pageProps: unknown; // 不修改
} & AppProps;

function MyApp(props: IProps) {
  const router: NextRouter = useRouter();
  // 勿删
  router.locale && i18nUtil.updateLocale(router.locale);

  const { Component, pageProps } = props;

  pageProps.locale = router.locale;
  pageProps.locales = router.locales;
  pageProps.route = router.route;

  /**
   * 修复控制台：Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format. This will lead to a mismatch between the initial, non-hydrated UI and the intended UI. To avoid this, useLayoutEffect should only be used in components that render exclusively on the client. See https://reactjs.org/link/uselayouteffect-ssr for common fixes.
   * https://usehooks-ts.com/react-hook/use-isomorphic-layout-effect
   */
  if (!process.browser) React.useLayoutEffect = React.useEffect;

  useEffect(() => {
    if (typeof window === "undefined") return;
    localStorage.setItem(LANGUAGE, router.locale ?? EN);
    // 判断浏览器是否支持webp格式的图片
    checkSupportWebp("lossy", (result) => {
      localStorage.setItem(FORMATWEBP, result ? "" : "format,png");
    });

    // app嵌入时 设置为登陆状态 储存jwtToken 部分app页面需要从客户端写入jwt
    const jwtToken = getQueryValue("jwtToken");
    const user = decodeURIComponent(getQueryValue("user"));
    if (jwtToken && user) {
      cookieUtil.set(getSessionStorageTokenKey(location.host), jwtToken);
      sessionStorage.setItem(getSessionStorageTokenKey(location.origin), jwtToken ?? "");
      sessionStorage.setItem(USER, user);
      localStorage.setItem(IS_AUTHENTICATED, "true");
      localStorage.setItem(USER_AUTH, "customer");
    }

    // 刷新页面时 增加埋点  TODO:关闭会误报
    window.onbeforeunload = () => {
      reportEvent({ moduleName: GA_EVENT_NAME.common.pageRefresh });
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
  useEffect(() => {
    if (apikeyGa) ReactGA.initialize(apikeyGa);
    if (apikeyGtm) TagManager.initialize({ gtmId: apikeyGtm });
    // 点击跳转路由的统一上报事件，不用单独上报
    // router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeStart", handleRouteChangeStart);

    return () => {
      // router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router.events]);

  // 处理antd moment.js替换dayjs与国际化
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  if (router.locale === ZH_CN) {
    dayjs.locale(DAYJSZH);
  } else {
    dayjs.locale(DAYJSEN);
  }

  const locale = router.locale === ZH_CN ? zhCN : enUS;
  const defaultTheme = "mode-dark";

  const renderContent = (
    <WithLogging>
      <Provider store={store}>
        <ConfigProvider locale={locale}>
          <PersistGate loading={null} persistor={persistor} />
          <ThemeProvider defaultTheme={defaultTheme}>
            <Component {...pageProps} />
          </ThemeProvider>
        </ConfigProvider>
      </Provider>
    </WithLogging>
  );

  return isDev ? (
    <Inspector
      disableLaunchEditor={!isDev}
      // props see docs:
      // https://github.com/zthxxx/react-dev-inspector#inspector-component-props
      keys={["control", "shift", "command", "c"]}
      onClickElement={({ codeInfo }: InspectParams) => {
        let absolutePath = "/Users/elaine/Desktop/monorepo_aspen_web"; // 你的项目目录地址
        if (codeInfo?.absolutePath) absolutePath = codeInfo?.absolutePath;
        const { lineNumber, columnNumber, relativePath } = codeInfo || {};
        // you can change the url protocol if you are using in Web IDE
        window.open(`vscode://file/${absolutePath}/${relativePath}:${lineNumber}:${columnNumber}`);
      }}>
      {renderContent}
    </Inspector>
  ) : (
    <>{renderContent}</>
  );
}

export default MyApp;
